import styled from "styled-components";
import { NotAuthedHeader } from "../../components/NotAuthedHeader";
import { useState } from "react";
import { LoginForm } from "./LoginForm";
import { ForgotPassword } from "./ForgotPassword";

export const Auth = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  return (
    <StyledAuth className="login-content">
      <div className="bglogin">
        <NotAuthedHeader />
        {forgotPassword ? (
          <ForgotPassword onClose={() => setForgotPassword(false)} />
        ) : (
          <LoginForm onForgotPassword={() => setForgotPassword(true)} />
        )}
      </div>
    </StyledAuth>
  );
};

const StyledAuth = styled.div`
  min-height: 100vh;
  label {
    text-align: left !important;
  }
`;

import styled from "styled-components";
import { Field } from "./Field";
import { useEffect, useState } from "react";
import {
  createUser,
  editUser,
  getUserById,
  updateUser,
} from "../../api/methods";
import cogoToast from "cogo-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar } from "./Avatar";

export const Profile = ({
  initEdit,
  user,
  onUpdatedUserData,
  onUpdateUser,
}) => {
  const { id } = useParams();
  const [edit, setEdit] = useState(initEdit);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleChangeField = (field, value) =>
    setData({ ...data, [field]: value });

  const handleCancel = () => {
    if (user) {
      setEdit(false);
      setData(user);
    } else {
      navigate("/staff");
    }
  };
  const handleSave = () => {
    if (user) {
      updateUser(data)
        .then((resp) => {
          setEdit(false);
          cogoToast.success(resp?.data?.msg ?? "Success");
          onUpdatedUserData();
        })
        .catch((resp) => {
          cogoToast.error("Error");
          setEdit(false);
        });
    } else if (id) {
      editUser(data)
        .then((resp) => {
          cogoToast.success(resp?.data?.msg ?? "Success");
          navigate("/staff");
        })
        .catch((resp) => {
          const errors = resp?.response?.data?.errors
            ? Object?.entries(resp?.response?.data?.errors)?.map(
                (e) => e[1]?.[0]
              )
            : [];
          if (errors?.length > 0) {
            errors?.map((err) => cogoToast.error(err ?? "Error"));
          } else {
            cogoToast.error("Error");
          }
        });
    } else {
      createUser(data)
        .then((resp) => {
          cogoToast.success(resp?.data?.msg ?? "Success");
          navigate("/staff");
        })
        .catch((resp) => {
          const errors = resp?.response?.data?.errors
            ? Object?.entries(resp?.response?.data?.errors)?.map(
                (e) => e[1]?.[0]
              )
            : [];
          if (errors?.length > 0) {
            errors?.map((err) => cogoToast.error(err ?? "Error"));
          } else {
            cogoToast.error("Error");
          }
        });
    }
  };

  useEffect(() => {
    if (user) {
      setData(user);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      getUserById(id).then((resp) => {
        setData(resp?.data?.data);
      });
    }
  }, [id]);

  return (
    <StyledProfile>
      {data ? (
        <div className="profile-header">
          <div className="title">Public Profile</div>
          <Avatar data={data} isEdit={!!user} onUpdateUser={onUpdateUser} />
        </div>
      ) : null}
      <Field
        label="First Name"
        value={data?.first_name}
        onChange={(val) => handleChangeField("first_name", val)}
        edit={edit}
      />
      <Field
        label="Last Name"
        value={data?.last_name}
        onChange={(val) => handleChangeField("last_name", val)}
        edit={edit}
      />
      <Field
        label="Position"
        value={data?.position}
        onChange={(val) => handleChangeField("position", val)}
        edit={edit}
      />
      <Field
        label="Department"
        value={data?.department}
        onChange={(val) => handleChangeField("department", val)}
        edit={edit}
      />
      <Field
        label="Team"
        value={data?.team}
        onChange={(val) => handleChangeField("team", val)}
        edit={edit}
      />
      <Field
        label="Office"
        value={data?.office}
        onChange={(val) => handleChangeField("office", val)}
        edit={edit}
      />
      <Field
        label="Email address"
        value={data?.email}
        onChange={(val) => handleChangeField("email", val)}
        edit={edit}
      />
      <Field
        label="Telefon"
        value={data?.telefon}
        onChange={(val) => handleChangeField("telefon", val)}
        edit={edit}
      />
      {!id && !user && (
        <Field
          label="Password"
          value={data?.password}
          onChange={(val) => handleChangeField("password", val)}
          edit={edit}
        />
      )}
      <div
        className="px-4 py-3 bg-gray-50 text-right sm:px-6 footer"
        id="profileFooter"
      >
        {edit ? (
          <>
            <input
              type="submit"
              name="commit"
              value="Save"
              className="c-button c-button--positive"
              onClick={handleSave}
            />
            <button
              type="button"
              className="c-button c-button--secondary toggle-edit-box"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            className="c-button toggle-edit-box"
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )}
      </div>
    </StyledProfile>
  );
};

const StyledProfile = styled.div`
  width: 90%;
  max-width: 936px;
  margin: 58px auto;
  background: #fff;
  border-radius: 0.25rem;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: hidden;
  .profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .title {
      font-size: 18px;
      color: rgb(22 30 46 / 1);
      line-height: 1.5rem;
      font-weight: 500;
    }
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      width: 120px;
      border-radius: 100%;
      background: #9fa6b2;
      font-size: 40px;
      color: #fff;
      font-weight: bold;
    }
  }
  #profileFooter {
    margin-top: 10px;
    display: flex;
    justify-content: center !important;
    gap: 20px;

    button,
    input {
      width: 100%;
    }
  }
`;

import styled from "styled-components";
import { Header } from "./Header";
import { Row } from "./Row";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { deleteUserById, getUsers, printCalendarDate } from "../../api/methods";
import cogoToast from "cogo-toast";
import { Tracking } from "../../components/Tracking/Tracking";
import { InfoList } from "./InfoList";
import { Confirm } from "../../components/Confirm";
import loader from "../../assets/loading.svg";

export const Staff = () => {
  const [data, setData] = useState([]);
  const [openInfo, setOpenInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(null);

  const handleGetUsers = () => {
    setLoading(true);
    getUsers().then((resp) => {
      setLoading(false);
      setData(resp?.data?.users ?? []);
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  const handleDelete = (id) => {
    deleteUserById(id)
      .then(() => {
        cogoToast.success("Success");
        handleGetUsers();
      })
      .catch(() => cogoToast.error("Success"));
  };

  const handleDeleteConfirm = () => {
    handleDelete(deleteModal);
    setDeleteModal(null);
  };

  const handlePrint = (id) => {
    if (id) {
      setLoading(true);
      printCalendarDate(id)
        .then((resp) => setLoading(false))
        .catch((resp) => setLoading(false));
    }
  };

  return (
    <StyledStaff className="containerWrapper">
      {deleteModal && (
        <Confirm
          onClose={() => setDeleteModal(null)}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <div className="content-wrapper">
        {loading ? (
          <img src={loader} alt="" className="loaderWrapper" />
        ) : (
          <div className=" max-w-none print:w-full print:px-8 print:max-w-full">
            <div className="header-staff">
              <span className="text-2xl font-bold text-gray-900 leading-8">
                Staff
              </span>
              <NavLink to={"/worker"} className="header-add">
                <input
                  type="submit"
                  name="commit"
                  value="Add"
                  className="c-button c-button--positive"
                  // onClick={handleSave}
                />
              </NavLink>
            </div>
            <div className="c-m-users table-wrapper">
              <div className="flex flex-col justify-center">
                <div className="overflow-x-auto overflow-y-visible mt-2 ml-4 mr-4 border border-gray-300 rounded">
                  <table className="c-m-users__table relative divide-y divide-gray-300">
                    <Header />
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data?.map(
                        (
                          {
                            first_name,
                            last_name,
                            position,
                            department,
                            team,
                            email,
                            id,
                            ...otherData
                          },
                          i
                        ) => (
                          <React.Fragment key={id}>
                            <Row
                              name={first_name}
                              lastName={last_name}
                              position={position}
                              department={department}
                              team={team}
                              email={email}
                              id={id}
                              onDelete={() => setDeleteModal(id)}
                              onOpenInfo={() =>
                                setOpenInfo(openInfo === id ? null : id)
                              }
                              data={otherData}
                              onPrint={() => handlePrint(id)}
                            />
                            {openInfo === id ? (
                              <tr className="moreInfo">
                                <td colSpan={8}>
                                  <InfoList id={openInfo} />{" "}
                                </td>
                              </tr>
                            ) : null}
                          </React.Fragment>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </StyledStaff>
  );
};

const StyledStaff = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  .header-add {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .table-wrapper {
    max-width: calc(100svw - 10px);
    overflow: auto;
  }
  table {
    background: #ffffff;
    border: 1px solid rgb(210 214 220 / 1);
    border-radius: 0.25rem;
  }
  thead {
    background: #f4f5f7;
    color: #6b7280;
    border-bottom: 1px solid rgb(210 214 220 / 1);

    th {
      padding: 12px 24px;
      font-size: 12px;
    }
  }
  .header-staff {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }
  .moreInfo {
    background: rgb(226 226 227) !important;
    border: none;
    padding: 20px;
  }
  .loaderWrapper {
    margin: 30px auto;
    height: 40px;
  }
`;

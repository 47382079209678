import axios from "axios";
import { baseUrl, headers } from "./baseUrl";

export const login = (data) =>
  axios.post(`${baseUrl}sign-in`, null, { params: data });

export const getUser = () =>
  axios
    .get(`${baseUrl}get-profile`, headers())
    .then((resp) => resp)
    .catch(() => {
      //   localStorage.removeItem("user");
      //   window.location = "/#/auth";
      //   window.location.reload(true);
    });

export const updateUser = (user) =>
  axios.post(`${baseUrl}update-profile`, { user }, headers());
export const updateUserAvatar = (data) =>
  axios.post(`${baseUrl}update-avatar`, data, headers());
export const updateUserFiles = (data) =>
  axios.post(`${baseUrl}upload-documents`, data, headers());
export const resetPassword = (email) =>
  axios.post(`${baseUrl}reset-password?email=${email}`, null, headers());

export const getCalendar = () =>
  axios.get(`${baseUrl}get-calendar-date`, headers());
export const startTimer = (data) =>
  axios.post(`${baseUrl}start-timer`, data, headers());
export const endTimer = (data) =>
  axios.post(`${baseUrl}end-timer`, data, headers());
export const resetTimer = (id) =>
  axios.post(`${baseUrl}reset-timer?id=${id}`, null, headers());
export const deleteTimer = (id) =>
  axios.post(`${baseUrl}delete-timer`, { id }, headers());
export const editTimer = (data) =>
  axios.post(`${baseUrl}edit-timer`, data, headers());

export const startPauseTimer = (data) =>
  axios.post(`${baseUrl}start-pause-timer`, data, headers());
export const endPauseTimer = (data) =>
  axios.post(`${baseUrl}end-pause-timer`, data, headers());

export const getUsers = () => axios.get(`${baseUrl}get-users`, headers());

export const createUser = (user) =>
  axios.post(`${baseUrl}create-user`, { user }, headers());
export const editUser = (user) =>
  axios.post(`${baseUrl}update-users`, { user }, headers());
export const getUserById = (id) =>
  axios.get(`${baseUrl}get-user-by-id?id=${id}`, headers());
export const deleteUserById = (id) =>
  axios.post(`${baseUrl}delete-user?id=${id}`, null, headers());
export const getUserTimersById = (id) =>
  axios.get(`${baseUrl}get-user-times?id=${id}`, headers());

export const saveObsences = ({ data_absence, comment }) =>
  axios.post(`${baseUrl}save-absences`, { data_absence, comment }, headers());
export const getAbsences = (user_id) =>
  axios.get(`${baseUrl}get-absences?user_id=${user_id}`, headers());

export const printCalendarDate = (id) =>
  axios
    .post(`${baseUrl}get-calendar-date-print?user_id=${id}`, null, {
      headers: {
        ...headers().headers,
        "Content-Type": "application/pdf",
        // "Content-Description": "File Transfer",
      },
    })
    .then((resp) => {
      const aElem = document.createElement("a");
      aElem.href = resp?.data?.pdf;
      aElem.target = "_blank";
      aElem.download = "true";
      document.body.append(aElem);
      aElem.click();
      aElem.remove();
    });

import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  handleFormatDate,
  handleGetHours,
  handleSetTimeForDate,
} from "../helpers";
import cogoToast from "cogo-toast";
import { editTimer } from "../api/methods";

export const EditTimerModal = ({ onClose, timer, onSuccess }) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChangeField = (field, value) => {
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setErrors({ ...errors, [field]: field?.length === 0 });
  };

  useEffect(() => {
    setData({
      dateStart: handleGetHours(timer?.date_start),
      dateEnd: handleGetHours(timer?.date_end),
    });
  }, [timer]);

  const handleCheckIsFieldEmpty = (fieldName, e) =>
    setErrors({ ...errors, [fieldName]: e.target.value?.length === 0 });

  const handleGetCurrentHour = (h) => Number(h?.split(":")?.[0]);
  const handleGetCurrentMinutes = (h) => Number(h?.split(":")?.[1]);

  const handleCheckIsHoursIncorrent = () =>
    handleGetCurrentHour(data.dateStart) > handleGetCurrentHour(data.dateEnd);

  const handleCheckIsMinutesIncorrent = () => {
    const isSameHours =
      handleGetCurrentHour(data.dateStart) ===
      handleGetCurrentHour(data.dateEnd);

    if (isSameHours) {
      return (
        handleGetCurrentMinutes(data.dateStart) >
        handleGetCurrentMinutes(data.dateEnd)
      );
    } else {
      return false;
    }
  };

  const handleCheckFields = () => {
    if (data.dateStart?.length === 0 || data.dateEnd?.length === 0) {
      return false;
    } else if (
      handleCheckIsHoursIncorrent() ||
      handleCheckIsMinutesIncorrent()
    ) {
      //   cogoToast.error("Incorrect time");
      //   setErrors({ dateStart: true });
      return true;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (handleCheckFields()) {
      editTimer({
        id: timer?.id,
        dateStart: handleFormatDate(
          handleSetTimeForDate(timer?.date_start, data?.dateStart),
          true
        ),
        dateEnd: handleFormatDate(
          handleSetTimeForDate(timer?.date_end, data?.dateEnd),
          true
        ),
      })
        .then((resp) => {
          onSuccess(timer?.id, resp?.data?.timer);
          onClose();
          cogoToast.success("Success");
        })
        .catch(() => {
          cogoToast.error("Error");
        });
    }
  };

  return (
    <StyledEditTimerModal
      className="c-modal c-time-entry"
      data-action="click->pistachio--modal#onClick keydown@window->pistachio--modal#onKeyDown"
      data-controller="pistachio--modal"
      data-pistachio--remote-modal--handler-target="modal"
      data-show="false"
      style={{ display: "flex" }}
    >
      <div className="c-modal__inner">
        <div className="c-modal__content">
          <div className="animate-pulse flex space-x-4 w-64 hidden">
            <div className="flex-1 space-y-4 py-1">
              <div className="h-6 bg-blue-200 rounded"></div>
              <div className="h-12 bg-blue-200 rounded"></div>
              <div className="flex flex-row gap-4">
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
                <div className="h-8 bg-blue-200 rounded w-1/2"></div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="c-modal__title">
              Edit Timer{" "}
              <div className="c-modal__title__sub">
                {/* {handleFormatDate(activeDay)} */}
              </div>
            </div>
            <form
              className="form w-full lg:w-[22rem]"
              action="/api/users/63/time_entries/new"
              method="post"
            >
              <div className="fields">
                <input
                  type="time"
                  value={data.dateStart}
                  onChange={(e) =>
                    handleChangeField("dateStart", e.target.value)
                  }
                  onBlur={(e) => handleCheckIsFieldEmpty("dateStart", e)}
                  className={`${errors?.dateStart && "error-field"}`}
                />
                <input
                  type="time"
                  value={data.dateEnd}
                  onChange={(e) => handleChangeField("dateEnd", e.target.value)}
                  onBlur={(e) => handleCheckIsFieldEmpty("dateEnd", e)}
                  className={`${errors?.dateEnd && "error-field"}`}
                />
              </div>
              <div className="form-actions justify-between">
                <div className="flex">
                  <input
                    type="button"
                    id="new_time_entry"
                    name="commit"
                    value="Save"
                    className="c-button c-button--positive"
                    data-time-sheet--form-target="submitButton"
                    onClick={handleSave}
                    disabled={errors?.dateStart || errors?.dateEnd}
                  />
                  <button
                    type="button"
                    className="c-button c-button--secondary modal-close"
                    data-action="#close"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StyledEditTimerModal>
  );
};

const StyledEditTimerModal = styled.div`
  .c-modal__inner {
    width: 95svw;
    max-width: 300px;
  }
  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .error-field {
    border: 1px solid red;
  }
`;

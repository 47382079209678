import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { Auth } from "./pages/Auth/Auth";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Header } from "./components/Header/Header";
import { Profile } from "./pages/Profile/Profile";
import { Staff } from "./pages/Staff/Staff";
import { useEffect, useState } from "react";
import { getUser } from "./api/methods";
import { Calendar } from "./pages/Calendar/Calendar";

function App() {
  const { pathname } = useLocation();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);

  const handleGetUser = () => {
    if (token) {
      getUser().then((resp) => {
        const userData = resp?.data?.user;
        if (userData) {
          setUser(resp?.data?.user);
        } else {
          localStorage.removeItem("token");
          window.location.pathname = "/auth";
        }
      });
    }
  };

  const handleUpdateUser = (data) => setUser({ ...user, ...data });

  useEffect(() => {
    handleGetUser();
  }, []);

  useEffect(() => {
    token && handleGetUser();
  }, [token]);

  useEffect(() => {
    const isToken = localStorage.getItem("token");
    setToken(isToken);
  }, [pathname]);

  if (!token) {
    return (
      <div id="app">
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="*" element={<Auth />} />
        </Routes>
      </div>
    );
  }

  return (
    <div id="app">
      {["/", "/auth", "/about", "/kontakt"]?.includes(pathname) ? null : (
        <Header user={user} />
      )}
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route
          path="/dashboard"
          element={<Dashboard user={user} onUpdatedUserData={handleGetUser} />}
        />
        <Route
          path="*"
          element={<Dashboard user={user} onUpdatedUserData={handleGetUser} />}
        />
        <Route
          path="/profile"
          element={
            <Profile
              user={user}
              onUpdatedUserData={handleGetUser}
              onUpdateUser={handleUpdateUser}
            />
          }
        />
        <Route path="/staff" element={<Staff />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/worker" element={<Profile initEdit />} />
        <Route path="/worker/:id" element={<Profile initEdit />} />
      </Routes>
    </div>
  );
}

export default App;

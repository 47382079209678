import moment from "moment";

export const addZero = (num) => (num >= 10 ? num : `0${num}`);

export const handleFormatDate = (d, isRequest, isMonth) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(d);
  const day = date.getDate();
  const month = 1 + date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return isMonth
    ? `${monthNames[month - 1]} ${year}`
    : isRequest
    ? `${year}-${addZero(month)}-${addZero(day)} ${addZero(hours)}:${addZero(
        minutes
      )}:${addZero(seconds)}`
    : `${addZero(day)}.${addZero(month)}.${year}`;
};

export const handleGetToday = (isParsed) => {
  const date = new Date();
  date.setHours(0);

  return isParsed ? Date.parse(date) : date;
};

export const handleGetHours = (d) => {
  const date = new Date(d);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${addZero(hours)}:${addZero(minutes)}`;
};

export const handleGetTimeValue = (secondstotal) => {
  const hours = Math.floor(
    (secondstotal % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((secondstotal % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((secondstotal % (1000 * 60)) / 1000);
  return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
};

export const handleGetTimerValue = (dStart, dEnd, isSeconds) => {
  const secondstotal = dEnd.getTime() - dStart.getTime();

  return isSeconds ? secondstotal : handleGetTimeValue(secondstotal);
};

export const handleGetTotalSeconds = (data) =>
  data?.filter((t) => t.date_end)?.length > 0
    ? handleGetTimeValue(
        data
          ?.filter((t) => t.date_end)
          ?.map((t) =>
            handleGetTimerValue(
              new Date(t?.date_start),
              new Date(t?.date_end),
              true
            )
          )
          ?.reduce((a, b) => a + b)
      )
    : "0:00";

export const handleGetDays = (date) => {
  const startMonth = moment(date).startOf("month").toDate();
  const endMonth = moment(date).endOf("month").toDate();

  let daysArr = [new Date(startMonth)];
  let tempDate = startMonth;

  while (tempDate < endMonth) {
    tempDate.setUTCDate(tempDate.getUTCDate() + 1);
    tempDate < endMonth && daysArr.push(new Date(tempDate));
  }

  return daysArr;
};

export const handleFormatStringTime = (time) => {
  const splitedTime = time?.split(":");
  const hours = Number(splitedTime?.[0] ?? 0);
  const minutes = Number(splitedTime?.[1] ?? 0);

  return { hours, minutes };
};

export const handleSetTimeForDate = (d, time) => {
  const date = new Date(d);
  const { hours, minutes } = handleFormatStringTime(time);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  return date;
};

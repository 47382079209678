import styled from "styled-components";
import { Header } from "./Header";
import { useEffect, useState } from "react";
import { Table } from "./Table";
import moment from "moment";
import { handleGetDays } from "../../helpers";
import { getUsers, printCalendarDate } from "../../api/methods";
import loader from "../../assets/loading.svg";

export const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [days, setDays] = useState(handleGetDays(new Date()));
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetUsers = () => {
    setLoading(true);
    getUsers().then((resp) => {
      setLoading(false);
      setUsers(resp?.data?.users ?? []);
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    setDays(handleGetDays(date));
  }, [date]);

  const handlePrint = (id) => {
    if (id) {
      setLoading(true);
      printCalendarDate(id)
        .then((resp) => setLoading(false))
        .catch((resp) => setLoading(false));
    }
  };

  return (
    <StyledCalendar className="containerWrapper">
      {loading ? (
        <img src={loader} alt="" className="loading" />
      ) : (
        <>
          <Header date={date} onChangeDate={(val) => setDate(val)} />
          <Table days={days} users={users} onPrint={handlePrint} />
        </>
      )}
    </StyledCalendar>
  );
};

const StyledCalendar = styled.div`
  padding: 0 32px;
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
  .loading {
    margin: 30px auto;
    height: 40px;
  }
`;
